import Vue from 'vue';
import VueRouter from 'vue-router';
import guards from './guards';

const Welcome = () => import(/* webpackChunkName: "route-Welcome" */'../views/Welcome.vue');
const AuthHome = () => import(/* webpackChunkName: "route-AuthHome" */'../views/AuthHome.vue');
const EditKeyword = () => import(/* webpackChunkName: "route-EditKeyword" */'../views/EditKeyword.vue');
const Init = () => import(/* webpackChunkName: "route-Init" */'../views/Init.vue');
const Login = () => import(/* webpackChunkName: "route-Login" */'../views/Login.vue');
const SocialLogin = () => import(/* webpackChunkName: "route-SocialLogin" */'../views/SocialLogin.vue');
const Register = () => import(/* webpackChunkName: "route-Register" */'../views/Register.vue');
const ListQuestion = () => import(/* webpackChunkName: "route-ListQuestion" */'../views/ListQuestion.vue');
const EditQuestion = () => import(/* webpackChunkName: "route-EditQuestion" */'../views/EditQuestion.vue');
const EditTip = () => import(/* webpackChunkName: "route-EditTip" */'../views/EditTip.vue');
const ListTip = () => import(/* webpackChunkName: "route-ListTip" */'../views/ListTip.vue');
const ListCompetency = () => import(/* webpackChunkName: "route-ListCompetency" */'../views/ListCompetency.vue');
const ListDimension = () => import(/* webpackChunkName: "route-ListDimension" */'../views/ListDimension.vue');
const ListKeyword = () => import(/* webpackChunkName: "route-ListKeyword" */'../views/ListKeyword.vue');
const ListSaleLeads = () => import(/* webpackChunkName: "route-ListSaleLeads" */'../views/ListSaleLeads.vue');
const ListSubCompetency = () => import(/* webpackChunkName: "route-ListSubCompetency" */'../views/ListSubCompetency.vue');
const ListKeywordHistory = () => import(/* webpackChunkName: "route-ListKeywordHistory" */'../views/ListKeywordHistory.vue');
const UserManagementHome = () => import(/* webpackChunkName: "route-UserManagementHome" */'../views/UserManagementHome.vue');
const ListJoinRequests = () => import(/* webpackChunkName: "route-ListJoinRequests" */'../views/ListJoinRequests.vue');
const JobDescriptionLookup = () => import(/* webpackChunkName: "route-JobDescriptionLookup" */'../views/JobDescriptionLookup.vue');
const SavedSkillSets = () => import(/* webpackChunkName: "route-SavedSkillSets" */'../views/SavedSkillSets.vue');
const SavedInterviews = () => import(/* webpackChunkName: "route-SavedInterviews" */'../views/SavedInterviews.vue');
const StartInterview = () => import(/* webpackChunkName: "route-StartInterview" */'../views/StartInterview.vue');
const Interview = () => import(/* webpackChunkName: "route-Interview" */'../views/Interview.vue');
const Keywords = () => import(/* webpackChunkName: "route-Keywords" */'../views/Keywords.vue');
const EventLogs = () => import(/* webpackChunkName: "route-EventLogs" */'../views/EventLogs.vue');
const EditTrainingItem = () => import(/* webpackChunkName: "route-EditTrainingItem" */'../views/EditTrainingItem.vue');
const AISettings = () => import(/* webpackChunkName: "route-AISettings" */'../views/AISettings.vue');

const Clouds = () => import(/* webpackChunkName: "route-Clouds" */'../views/Clouds.vue');
const Redirect = () => import(/* webpackChunkName: "route-Clouds" */'../views/Redirect.vue');
const Search = () => import(/* webpackChunkName: "route-Search" */'../views/Search.vue');
const PasswordReset = () => import(/* webpackChunkName: "route-PasswordReset" */'../views/PasswordReset.vue');
const FeedbackItems = () => import(/* webpackChunkName: "route-FeedbackItems" */'../views/FeedbackItems.vue');
const JobDescription = () => import(/* webpackChunkName: "route-JobDescription" */'../views/Search/JobDescription.vue');
const ResearchSkills = () => import(/* webpackChunkName: "route-ResearchSkills" */'../views/Search/ResearchSkills.vue');

Vue.use(VueRouter);

// application routes
const appRoutes = [
  {
    path: '/Welcome',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/auth-home',
    name: 'AuthHome',
    component: AuthHome,
    meta: {
      isAuthenticated: true,
    },
  },
  {
    path: '/',
    name: 'ResearchSkills',
    component: ResearchSkills,
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: Redirect,
  },
  {
    path: '/keywords',
    name: 'Keywords',
    component: Keywords,
  },
  {
    path: '/clouds',
    name: 'Clouds',
    component: Clouds,
  },
  {
    path: '/job-description/search',
    name: 'jobDescriptionLookup',
    component: JobDescriptionLookup,
  },
  {
    path: '/search/job-description',
    name: 'search-JobDescription',
    component: JobDescription,
  },
  {
    path: '/admin/event-logs',
    name: 'admin-EventLogs',
    component: EventLogs,
  },
  {
    path: '/admin/ai-settings',
    name: 'admin-AISettings',
    component: AISettings,
  },
  {
    path: '/admin/training-item',
    name: 'admin-EditTrainingItem',
    component: EditTrainingItem,
  },
  {
    path: '/admin/keywordHistory',
    name: 'admin-ListKeywordHistory',
    component: ListKeywordHistory,
  },
  {
    path: '/admin/sale-leads',
    name: 'admin-ListSaleLeads',
    component: ListSaleLeads,
  },
  {
    path: '/admin/keywords',
    name: 'admin-ListKeyword',
    component: ListKeyword,
  },
  {
    path: '/admin/sub-competencies',
    name: 'question-ListSubCompetency',
    component: ListSubCompetency,
  },
  {
    path: '/admin/dimensions',
    name: 'question-ListDimension',
    component: ListDimension,
  },
  {
    path: '/admin/competencies',
    name: 'question-ListCompetency',
    component: ListCompetency,
  },
  {
    path: '/admin/question',
    name: 'question-EditQuestion',
    component: EditQuestion,
  },
  {
    path: '/admin/questions',
    name: 'question-ListQuestion',
    component: ListQuestion,
  },
  {
    path: '/admin/tip',
    name: 'question-EditTip',
    component: EditTip,
  },
  {
    path: '/admin/tips',
    name: 'question-ListTip',
    component: ListTip,
  },
  {
    path: '/edit-keyword',
    name: 'EditKeyword',
    component: EditKeyword,
  },
  {
    path: '/auth/login',
    name: 'auth-Login',
    alias: '/login',
    component: Login,
  },
  // {
  //   path: '/auth/external-login',
  //   name: 'auth-ExternalLogin',
  //   component: ExternalLogin,
  // },
  {
    path: '/auth/register',
    name: 'auth-Register',
    alias: '/register',
    component: Register,
  },
  {
    path: '/auth/social-login',
    name: 'auth-Social',
    component: SocialLogin,
  },
  {
    path: '/init',
    name: 'app-Init',
    component: Init,
  },
  {
    path: '/auth/password-reset',
    alias: '/password-reset',
    name: 'auth-PasswordReset',
    component: PasswordReset,
  },
  {
    path: '/admin/users',
    name: 'admin-UserManagementHome',
    component: UserManagementHome,
  },
  {
    path: '/admin/feedback',
    name: 'admin-ListFeedback',
    component: FeedbackItems,
  },
  {
    path: '/admin/join-requests',
    name: 'admin-ListJoinRequests',
    component: ListJoinRequests,
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/skill-sets',
    name: 'SavedSkillSets',
    component: SavedSkillSets,
  },
  {
    path: '/interview/start',
    name: 'StartInterview',
    component: StartInterview,
  },
  {
    path: '/interview/:id',
    name: 'Interview',
    component: Interview,
  },
  {
    path: '/interviews',
    name: 'Interviews',
    component: SavedInterviews,
  },
];
const routes = [...appRoutes];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(guards);

export { routes };
export { router };
